export const erik = (e) => {
  const txt = e === 'dk' ? (
      <p id='dk'>
          Højsangen kap 2:<br />
          Jeg er Sarons rose. Dalenes lilje.<br />
          Som en lilje blandt torne er min veninde blandt piger.<br /><br />
          Nørrebros røde rose.<br /><br />
          Af Præst Erik Bastian Bock 2011.
          <br /><br />
          Før ordet blev til, var sangen. Barnets gråd, når det tror sig forladt af sin mor, er en sang. Fortvivlelsens sang. Raseriets sang, som er et skrig i tomheden efter kærlighed og omsorg.<br />
          Der er elskerens sang efter den elskede, når han længes. Sangen løfter sig mod Gud, ikke i én bevægelse udenfor, men inde fra dit hjerte, som er tættere på, end dig selv. Her bor din længsel. Guds sted: Dit Jeg! Dette gådefulde og grænseløse sted, som du ikke kender og ej heller må kende: Du må ikke gøre dig billede af Gud! Jeg er! Jeg er! Jeg er Liv!<br /><br />
          Og du skal elske dit liv, som det er. Du skal elske verden, som den er. Men Menneskesønnen gjorde oprør: Jeg har overvundet verden! Og han gjorde oprør mod Gud. Da han sagde: Jeg og Faderen er ét, skubbede han da ikke sin far fra tronen? Denne verdens fyrste? I en verden, der har henvist de gamle myter til religionshistorien, og har sat de gamle guder på porten, er dit Jeg det sidste hemmelige og skjulte, som er tilbage i dit liv. Manipulationer, fordrejelser og forræderi har ikke kunnet lægge menneskets Jeg i graven: Jeg lever, og I skal leve!<br />
          De år Liv Carlé boede på Nørrebro, sad hun hver søndag i Helligkors. For præsten var hun et memento: Vær i sandhed! Kun halvvejs, nærved og næsten kunne han leve op til det. Sandhedens øjeblik fandt først sted i mysteriet. Nadveren som er nådens sted. Omkring alteret knæler syndere og synderinder og modtager menneskesønnens legemes brød og hans blods vin. For Guds og min skyld. En opstand mod døden. Også i de gamle myter var der oprør mod døden. Også Attis, Tammuz, Osiris og Orfeus måtte drage til Hades. Også de genopstod. Vi hører gråden ved deres ligbegængelse og latteren ved deres genopstandelse. Med tilbageholdt åndedræt lytter vi: Kan vi høre? Gråden og latteren er så fjern, langt borte. Vi mangler den nøgle, der kan åbne døren.<br /><br /><img className='about_image_02' src="http://livcarlemortensen.com/content/sacrament/01.jpg" alt="Fine Art Photography" />
          Når Liv med sin rosenkrans af pigtråd om panden knæler ved alteret, er hun med til at lægge tiden død. Hendes sår og hendes hengivelse, som er for stærk til denne verden, kommer her på plads. Et billede hun selv har opsøgt med kameraet igen og igen. Så sagte, så varsomt. Den Hellige Augustin siger, at i det øjeblik brødet berører den knælendes læber, da kysser Menneskesønnen sin søster og sin brud. Sjælens udtrykte billede. Augustin bruger et billede fra "Sangenes sang" Højsangen, hvor kongen taler med dronningen, Sulamith i et kærlighedssprog, som opstår i det hellige øjeblik, hvor to elskende mødes ansigt til ansigt. Måske er denne sang brudstykker af bryllupsliturgi fra Salomons tempel, men siden har det haft sin plads i kanon, og fortolkere har i allegorisk lys fået denne glødende lyrik spejlvendt og fremkaldt det som billeder på Gud. Senere for de kristne blev Jesus, som åbenbarede Gud, den konge som taler med dronningen, sin elskede sjæl.<br /><br />
          Jeg ser Liv som kriger med sit fotografiske øje gå til angreb på en Barbiekultur, hvor æggestokkene skal bortopereres og jomfruelig skønhed skal skænkes den, der lægger sig blot for kniven. Barbieprinsessen skal stå brud! Men er Liv ikke selv en Barbiepunker? Nuvel Liv, der har stof i sig til både helgeninde og profet, ser jeg som mere end et symptom på vor tid. Det underlige dobbeltvæsen, som hedder menneske, må kæmpe med ånd og stof. Modsætninger, som hvor de opleves med Livs tragiske alvor, skaber det vi kalder religion. Spændt ud mellem to verdener. Med hjertet i den gamle verden og med hjernen i den ny, går hun på en bro over en rivende strøm. Over en gabende afgrund kæmper hun med sig selv. Kæmper med sit liv. Med dødens angst i hjertet lukker hun sine øjne og stoler på det indre lys: Troen! <br /><br /><br /><br />
      </p>
    ) : (
      <p id='uk'>Song of Songs chapter 2:<br />
          I am the rose of Saron. Lily of the valley.<br />
          As a lily among thorns is my friend among maidens.<br /><br />
          Nørrebro's red rose.<br /><br />
          By Priest Erik Bastian Bock 2011.<br /><br />
          Before the word was the song. The child's cry when it thinks it has been abandoned by its
          mother is a song. The song of despair. The song of rage, which is a cry in the void for love
          and care. There is the lover's song for the beloved when he longs. The song rises towards
          God, not in one movement outside, but from within your heart, which is closer than yourself.
          Here lives your longing. God's Place: Your Me! This mysterious and boundless place, which
          you do not know and must not know: You must not make yourself the image of God! I am! I
          am! I am Life!<br /><br />
          And you have to love your life as it is. You must love the world as it is. But the
          Son of Man rebelled: I have overcome the world! And he rebelled against God. When he said:
          I and the father are one, did he then not push his father from the throne? The prince of this world?<br /><br />In a world that has relegated the old myths to religious history and put the old gods at the
          gate, your Self is the last secret and hidden thing left in your life. Manipulations, distortions
          and betrayal have not been able to put the human I in the grave: I live and you shall live!<br />
          During the years Liv Carlé lived in Nørrebro, she sat in Helligkors church every Sunday. For the
          priest, she was a reminder: Be true! Only halfway close and almost he live up to it. The
          moment of truth only occurred in the mystery. The Eucharist, which is the place of grace.
          Around the altar, sinners kneel and receive the bread of the Son of Man's body and the wine
          of his blood. For God's sake and mine. A rebellion against death. Also, in the old myths there
          was rebellion against death. Attis, Tammuz, Osiris and Orpheus also had to go to Hades.
          They too were resurrected. We hear the weeping at their burial and the laughter at their
          resurrection. With bated breath we listen: Can we hear? The crying and the laughter are so
          far, far away. We are missing the key that can open the door.<br /><br /><img className='about_image_02' src="http://livcarlemortensen.com/content/sacrament/01.jpg" alt="Fine Art Photography" />
          When Liv, with her rosary of barbed wire on her forehead, kneels at the altar, she helps to put
          time to rest. Her wounds and her devotion, which is too strong for this world, come into place
          here. An image she herself has sought out with the camera again and again. So softly, so
          carefully. Saint Augustine says that at the moment the bread touches the lips of the kneeling,
          the Son of Man kisses his sister and his bride. The expressed image of the soul. Augustine
          uses an image from the "Song of Songs" the Song of Songs, where the king speaks to the
          queen, Shulamith in a love language that occurs in the sacred moment when two lovers meet
          face to face. Perhaps this song is fragments of a wedding liturgy from Solomon's Temple, but
          since then it has had its place in the canon, and interpreters have mirrored this glowing lyric in
          an allegorical light and evoked it as images of God the Lord's love.
          Later, for the Christians, Jesus, who revealed God, became the king who speaks to the
          queen, his beloved soul.<br /><br />
          I see Liv as a warrior with her photographic eye attacking a Barbie
          culture where the ovaries must be operated on and virginal beauty must be bestowed on
          those who simply go under the knife. The Barbie princess is going to be a bride! But isn't Liv a
          Barbie punk herself? Liv, which has material in her for both a saint and a prophet, I see more
          than a symptom of our times. The strange dual being called man must contend with spirit and
          matter. Contradictions, such as where they are experienced with the tragic seriousness of
          Life, create what we call religion. Stretched out between two worlds. With her heart in the old
          world and her brain in the new, she walks on a bridge over a raging stream. Over a gaping
          abyss, she fights with herself. Fighting with his life. With the fear of death in her heart, she
          closes her eyes and trusts in the inner light: Faith!
          <br /><br /><br /><br />
      </p>
    );
  return txt;
}