import React from 'react';
import Work from "./work";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative, Pagination, Mousewheel, HashNavigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import './home.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';
import {updateMenu} from '../jsfunctions';
import { useLocation } from 'react-router-dom';
import {isMobile, useMobileOrientation} from 'react-device-detect';

function Home() {
    let ls = useMobileOrientation();
    const tableHeight = ls.isLandscape && isMobile ? {height: '58vh'} : {height: '70vh'}
    let slideNo = window.location.hash.toString() === '#contact' ? 2 :
    window.location.hash.toString() === '#work' ? 1 : 0;

    let location = useLocation(null);
    React.useEffect(() => {}, [location, slideNo]);

    function resetSlide(swiper){
        slideNo = swiper.realIndex;
        setTimeout(()=>{slide2hash(swiper)}, 500);
    }

    function slide2hash(swiper) {
        switch(slideNo) {
            case 0 :
                window.location.hash = '';
                window.history.pushState(null, null, window.location.href.split('#')[0]);
            break;
            case 1 :
                window.location.hash = 'work';
            break;
            case 2 :
                window.location.hash = 'contact';
            break;
            default :
            break;
        }
        updateMenu();
    }

    return (
        <Swiper 
            style={{height: "100vh"}}
            initialSlide={slideNo}
            direction={'vertical'}
            mousewheel={true}
            pagination={{ clickable: true }}
            effect={'creative'}
            speed={isMobile ? '200' : '300'}
            hashNavigation={{
                watchState: true,
              }}
            creativeEffect={{
                prev: {
                    shadow: true,
                    translate: [0, 0, -400],
                },
                next: {
                    translate: [0, '100%', 0],
                },
            }}
            modules={[Pagination, EffectCreative, Mousewheel, HashNavigation]}
            grabCursor={true}
            className="FineArtPhotographySwiper"
            key={slideNo}
            onSlideChange={resetSlide}
        >
            <SwiperSlide className="home_slider1" key='home_slider1'>
                <div className="home_1_content">
                    <div className="top_menu">
                        <Link id="work" className="top_menu_item" to="/#work">Work</Link>
                        <Link id="about" className="top_menu_item" to="/about">About</Link>
                        <Link id="home" className="top_menu_item" to="/#contact">Contact</Link>
                    </div>
                    <h1 className="liv">Liv Carlé Mortensen</h1>
                    <h2 className="retro"><i>Retrospectively</i></h2>
                    <h2 className="fineart">Fine Art Photography</h2>
                    <Link id="work" className="top_menu_item" to="/#work">
                        <svg className="arrows">
                            <path className="a1" d="M0 0 L15 15 L30 0"></path>
                            <path className="a2" d="M0 10 L15 25 L30 10"></path>
                            <path className="a3" d="M0 20 L15 35 L30 20"></path>
                        </svg>
                    </Link>
                </div>
                <img className="home_1_bg" src="https://livcarlemortensen.com/content/amazon/02.jpg" alt="Liv Carlé Mortensen - Fine Art Photography" />
            </SwiperSlide>
            <SwiperSlide className="home_slider3" id='work' key='home_slider3' data-hash={'work'}>
                <Work />
            </SwiperSlide>
            <SwiperSlide className="home_slider2" key='home_slider2' data-hash={'contact'}>
                <div id='contact'>
                <table>
                    <tr style={tableHeight}>
                    <th colSpan="2">
                        <img className="selfimg" src="https://livcarlemortensen.com/content/liv_home_2.jpg" alt="Liv Carlé Mortensen - Fine Art Photography" />
                    </th>
                    </tr>
                    <tr>
                    <th colSpan="2">
                            <a href="mailto:amazon@livcarlemortensen.com" style={{margin: '0'}}>
                                amazon@livcarlemortensen.com
                            </a>
                        </th>
                    </tr>
                    <tr> 
                    <th className="bottom_insta">
                            <a href="https://www.instagram.com/mortensenlivcarle/" target='_new'>
                                <img style={{marginRight: '10px'}} src="https://livcarlemortensen.com/content/insta_logo_200.png"  alt="" />
                            </a>
                        </th>
                        <th className="bottom_insta">
                            <a href="https://www.facebook.com/profile.php?id=61557026908189" target='_new'>
                                <img style={{marginLeft: '10px'}} src="https://livcarlemortensen.com/content/fb.png"  alt="" />
                            </a>
                    </th> 
                    </tr>
                        <th colSpan='2' style={{height:'10vh'}}></th> 
                </table>
                </div>
            </SwiperSlide>
        </Swiper>
    )
}

  export default Home;
  