export const wpImages = (work) => { 
    let returnFolder = [];
    switch(work) {
        case 'spiral': {
            returnFolder = [
                { title: 'Spiral',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/spiral01.jpg'
                },
                { title: 'Valkyrie',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/spiral02.jpg'
                },
                { title: 'Fire',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/spiral03.jpg'
                },
                { title: 'Worrior',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/spiral04.jpg'
                }
            ];
            break;
        }
        case 'worrior' : {
            returnFolder = [
                { title: 'Worrior',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior01.jpg'
                },
                { title: 'The swing',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior02.jpg'
                },
                { title: 'Death of lover',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior03.jpg'
                },
                { title: 'Field meditation',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior04.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior05.jpg'
                },
                { title: 'Offering',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior06.jpg'
                },
                { title: 'The mothers',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior07.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior08.jpg'
                },
                { title: 'Lolitas glory hole',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior09.jpg'
                },
                { title: 'Glory me',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior10.jpg'
                },
                { title: 'Face me',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior11.jpg'
                },
                { title: 'Faceless',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior12.jpg'
                },
                { title: 'Mothers doll',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior13.jpg'
                },
                { title: 'Mother morning',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior14.jpg'
                },
                { title: 'Barbie girl',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior15.jpg'
                },
                { title: '8. March',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior16.jpg'
                },
                { title: 'Three mothers',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior17.jpg'
                },
                { title: 'Red line',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior18.jpg'
                },
                { title: 'Girl heaven',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior19.jpg'
                },
                { title: 'Tears',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior20.jpg'
                },
                { title: 'Babyboy',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior21.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior22.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior23.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior24.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior25.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior26.jpg'
                },
                { title: 'Worriors',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior27.jpg'
                },
                { title: 'Worrior boy',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior28.jpg'
                },
                { title: 'The girl with the bow',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior29.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior30.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior31.jpg'
                },
                { title: 'Gunhil´s sunflower',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior32.jpg'
                },
                { title: 'Faith',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior33.jpg'
                },
                { title: 'Rest in the shade',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior34.jpg'
                },
                { title: 'Without title',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior35.jpg'
                },
                { title: 'Orgasm',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior36.jpg'
                },
                { title: 'Be mine',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior37.jpg'
                },
                { title: 'Kim´s bitch',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior38.jpg'
                },
                {  title: 'Sky storms',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior39.jpg'
                },
                { title: 'Red',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior40.jpg'
                },
                { title: 'Lover´s dance',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior41.jpg'
                },
                { title: 'DNA',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior42.jpg'
                },
                { title: 'Ram',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior43.jpg'
                },
                { title: 'Sunflower',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior44.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior45.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior46.jpg'
                },
                { title: 'His hand',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior47.jpg'
                },
                { title: 'The wedding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/worrior48.jpg'
                }
            ];
            break;
        }
        case 'amazon' : {
            returnFolder = [
                { title: 'Amazon',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon01.jpg'
                },
                { title: 'Red bones',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon02.jpg'
                },
                { title: 'Self portrait without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon03.jpg'
                },
                { title: 'Self portrait without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon04.jpg'
                },
                { title: '12 Women',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon05.jpg'
                },
                { title: '12 Women',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon06.jpg'
                },
                { title: '12 Women',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon07.jpg'
                },
                { title: '12 Women',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon08.jpg'
                },
                { title: '12 Women',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon09.jpg'
                },
                { title: '12 Women',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon10.jpg'
                },
                { title: 'Lily of the valley',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon11.jpg'
                },
                { title: 'Mother',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon12.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon13.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon14.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon15.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon16.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon17.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon18.jpg'
                },
                { title: 'Map of birth',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon19.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon22.jpg'
                },
                { title: 'Nude study without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon23.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon24.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon25.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon26.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon27.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon28.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon29.jpg'
                },
                { title: 'Polaroid without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon30.jpg'
                },
                { title: 'Self portrait without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon31.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon33.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon34.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon35.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon36.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon37.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon38.jpg'
                },
                { title: '7 Endings breastfeeding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon39.jpg'
                },
                { title: 'Mother',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon40.jpg'
                },
                { title: 'The vibrator',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon42.jpg'
                },
                { title: 'Daniel´s scar',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon43.jpg'
                },
                { title: 'Seventeen',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon44.jpg'
                },
                { title: 'God´s light',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon45.jpg'
                },
                { title: 'Her gender',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon46.jpg'
                },
                { title: 'Without titel',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon47.jpg'
                },
                { title: 'Tulip hearts',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon48.jpg'
                },
                { title: 'The universe and a red tomato',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon50.jpg'
                },
                { title: 'The offering',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon58.jpg'
                },
                { title: 'Am i still your lover',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon59.jpg'
                },
                { title: 'The placenta of son Esajas',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon60.jpg'
                },
                { title: 'Coming home',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon61.jpg'
                },
                { title: 'Crap in yellow',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon62.jpg'
                },
                { title: 'Sacrifice',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon63.jpg'
                },
                { title: 'Life lives',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon64.jpg'
                },
                { title: 'Light red sky',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon65.jpg'
                },
                { title: 'Private showroom',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon66.jpg'
                },
                { title: 'Flying baby bird',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon69.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon70.jpg'
                },
                { title: 'Mothers angel',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon71.jpg'
                },
                { title: 'The heart of Nørrebro',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon73.jpg'
                },
                { title: 'Dragon girl',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon74.jpg'
                },
                { title: 'Lovers bed',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon75.jpg'
                },
                { title: 'Red cross',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon76.jpg'
                },
                { title: 'Girlpower',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon77.jpg'
                },
                { title: 'Childhood',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon78.jpg'
                },
                { title: 'Mother´s shoes',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon79.jpg'
                },
                { title: 'The wedding',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon81.jpg'
                },
                { title: 'Liv versus Cindy',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon82.jpg'
                },
                { title: 'Blind eye',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon83.jpg'
                },
                { title: 'Fuck',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon84.jpg'
                },
                { title: 'Childhood traces',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon85.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon86.jpg'
                },
                { title: 'Faithfulluntodeath',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon87.jpg'
                },
                { title: 'Faithfulluntodeath',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon88.jpg'
                },
                { title: 'Faithfulluntodeath',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon89.jpg'
                },
                { title: 'Faithfulluntodeath',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon90.jpg'
                },
                { title: 'Faithfulluntodeath',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon91.jpg'
                },
                { title: 'The wooden raft',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon92.jpg'
                },   
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon93.jpg'
                },
                { title: 'Father',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon94.jpg'
                },
                { title: 'Girl',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon95.jpg'
                },
                { title: 'Without title',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon96.jpg'
                },
                { title: 'My way',
                path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon97.jpg'
                }
            ];
            break;
        }
            case 'deletedFiles' : {
                returnFolder = [
                    { title: 'Without title',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon80.jpg'
                    },
                    { title: 'Red Toyota',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon72.jpg'
                    },
                    { title: 'Spiral',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon67.jpg'
                    },
                    { title: 'Son of mine',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon68.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon56.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon57.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon51.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon52.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon53.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon54.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon55.jpg'
                    },
                    { title: 'Yellow flower',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon49.jpg'
                    },
                    { title: 'Without titel',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon41.jpg'
                    },
                    { title: 'Letter from Kirsten',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon32.jpg'
                    },
                    { title: 'Without title',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon20.jpg'
                    },
                    { title: 'Without title',
                    path: 'http://livcarlemortensen.com/wp/wp-content/uploads/amazon21.jpg'
                    }
                ];
                break;
            }
        default : {
            break;
        }
    }
    return returnFolder;
};