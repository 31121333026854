import React from "react";
import { Link } from 'react-router-dom';

  function Menu({ close }) {
    return (
      <div className="menu">
        <ul>
          <li onClick={close}><Link id="home" className="menu-item" to="/#work">Work</Link></li>
          <li onClick={close}><Link id="about" className="menu-item" to="/about">About</Link></li>
          <li onClick={close}><Link id="home" className="menu-item" to="/#contact">Contact</Link></li>
        </ul>'
      </div>
    );
  }
  export default Menu;