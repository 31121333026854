import './App.css';
import React from 'react';
import Popup from "reactjs-popup";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import SlideShow from './components/slideshow';
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";
 
function App() {
  return (
    <Router>
        <div className="App" id="outer-container">
          <Popup
            modal
            overlayStyle={{ background: "rgba(0,0,0,1" }}
            closeOnDocumentClick={false}
            style={{height: '100vh'}}
            trigger={open => <BurgerIcon id='bugerMenu' open={open} />}
          >
            {close => <Menu close={close}/>}
          </Popup>
          </div>
          <div id="page-wrap">
            <Routes>
              <Route exat path="" element={<Home/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/work/amazon" element={<SlideShow imgFolder={'amazon'} />} />
              <Route path="/work/worrior" element={<SlideShow imgFolder={'worrior'} />} />
              <Route path="/work/spiral" element={<SlideShow imgFolder={'spiral'} />} />
            </Routes>
        </div>
      </Router>
  )
}

export default App;