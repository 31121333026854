import React from 'react';
import { Link } from 'react-router-dom';
import { useMobileOrientation} from 'react-device-detect';
import {wpImages} from './sliderInfo/wpImages';

import './work.css';
import 'swiper/css';

function Work() {
  const { isLandscape } = useMobileOrientation();
  const imgInfo = (folder) => {
    return String(wpImages(folder).length);
  }
    let returnTable;
    !isLandscape ? returnTable = (
      <table className='work_table'>
        <tr><th style={{height:'5vh'}}></th></tr>
          <tr>
            <div className='swipe_gallery_text'>
              <Link id="spiral" className="menu-item" to="/work/spiral">
                <img className='swipe_gallery_image' src="http://livcarlemortensen.com/wp/wp-content/uploads/spiral01.jpg" alt="Fine Art Photography" />
                <p>Spiral <span style={{fontSize: '12px'}}>1/{imgInfo('spiral')}</span></p>
                </Link>
            </div>
          </tr>
          <tr>
            <div className='swipe_gallery_text'>
              <Link id="worrior" className="menu-item" to="/work/worrior">
                <img className='swipe_gallery_image' src="http://livcarlemortensen.com/wp/wp-content/uploads/worrior01.jpg" alt="Fine Art Photography" />
                <p>Worrior <span style={{fontSize: '12px'}}>1/{imgInfo('worrior')}</span></p>
              </Link>
            </div>
          </tr>
          <tr> 
              <div className='swipe_gallery_text'>
                <Link id="amazon" className="menu-item" to="/work/amazon">
                <img className='swipe_gallery_image' src="http://livcarlemortensen.com/wp/wp-content/uploads/amazon01.jpg" alt="Fine Art Photography" />
                <p>Amazon <span style={{fontSize: '14px'}}>2007 </span><span style={{fontSize: '12px'}}>1/{imgInfo('amazon')}</span></p>
                </Link>
              </div>
          </tr>
          <th style={{height:'5vh'}}></th> 
        </table>
    ) :  returnTable = (
      <table className='work_table_landscape'>
        <th colSpan='3' style={{height:'10vh'}}></th> 
            <tr>
              <th>
                <div className='swipe_gallery_text'>
                  <Link id="spiral" className="menu-item" to="/work/spiral">
                    <img className='swipe_gallery_image' src="http://livcarlemortensen.com/wp/wp-content/uploads/spiral01.jpg" alt="Fine Art Photography" />
                    <p>Spiral <span style={{fontSize: '12px'}}>1/{imgInfo('spiral')}</span></p>
                    </Link>
                </div>
              </th>
              <th>
                <div className='swipe_gallery_text'>
                  <Link id="worrior" className="menu-item" to="/work/worrior">
                    <img className='swipe_gallery_image' src="http://livcarlemortensen.com/wp/wp-content/uploads/worrior01.jpg" alt="Fine Art Photography" />
                    <p>Worrior <span style={{fontSize: '12px'}}>1/{imgInfo('worrior')}</span></p>
                  </Link>
                </div>
              </th>
              <th>
                <div className='swipe_gallery_text'>
                  <Link id="amazon" className="menu-item" to="/work/amazon">
                  <img className='swipe_gallery_image' src="http://livcarlemortensen.com/wp/wp-content/uploads/amazon01.jpg" alt="Fine Art Photography" />
                  <p>Amazon <span style={{fontSize: '14px'}}>2007 </span><span style={{fontSize: '12px'}}>1/{imgInfo('amazon')}</span></p>
                  </Link>
                </div>
              </th> 
            </tr>
            <th style={{height:'10vh'}}>
            <Link id="work" className="top_menu_item" to="/#contact">
                <svg className="arrows_red">
                  <path className="a1" d="M0 0 L15 15 L30 0"></path>
                  <path className="a2" d="M0 10 L15 25 L30 10"></path>
                  <path className="a3" d="M0 20 L15 35 L30 20"></path>
                </svg>
            </Link>
            </th> 
        </table>
    );
    return returnTable;
  }

export default Work;