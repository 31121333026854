import React from 'react';
import { createRoot } from 'react-dom/client';
import './JosefinSlab-Thin.ttf';
import './App.css';
import App from './App';
import {iOS} from './jsfunctions';
import {isMobile} from 'react-device-detect';

window.addEventListener("DOMContentLoaded", function (e) {
  localStorage.setItem('lang', 'dk')
  const container = document.getElementById('root');
  const root = createRoot(container);
  isMobile ? document.body.classList.add("mobile") : document.body.classList.remove("mobile");
  iOS() ? document.body.classList.add("ios") : document.body.classList.remove("ios");
  root.render(<App tab="home" />);
});
