export const harald = (e, txtNo) => {
  let txt = '';
  switch(txtNo) {
    case 1: {
      txt = e === 'dk' ? (
        <p id='dk'>
            Min mor er billedkunstner, hun fotograferer.<br /><br />
            Af søn Harald 2007.<br /><br />
            Amazon<br />
            Kornet var højt på marken, i skellet for enden af haven, solen skinnede, og det var rigtig godt vejr, så mig, lillebror og mor ud i marken i det høje strå, og mor sagde: Det skal være nu, inden de høster kornet, så stilte vi os ud i det. Mor havde kameraet på stativ, og så fotograferede vi med selvudløseren, og stråene kildede, og Esajas sprang omkring. Mor siger altid hold den, hold den, når hun føler billedet er der, men det er vist kun noget hun kan føle, for vi kan jo ikke se i kameraet, når vi tager billedet<br /><br />
            Lige da vi var færdige med at tage billedet, lød der et brøl, og så kom den største mejetærsker jeg nogensinde har set, bare væltende afsted. Der var vi ret heldige, at vi var færdige med billedet, for ellers havde det billede aldrig været.
            For mig er amazonebilledet et stort minde på den sommer 2007. <br /><br />
            Da mor sagde, at vi skulle lave et amazonebillede, der troede jeg, at hun ville gå ind og hente en kniv og skære brystet af, sådan så vi kan lave billedet rigtigt. Sommetider er det hårdt arbejde, når vi laver de store værker, så står jeg i den samme stilling i lang tid, men det er jo også fint, for det bærer jo frugt til sidst at gøre det godt. Det er et stykke dybt seriøst arbejde, som jeg tager alvorligt, ellers bliver det jo ikke i orden, og så er der nok ingen, der vil kigge på det.<br /><br />
            Vi er amazoner i vores familie. Mor er nøgen på næsten alle billeder, altså vi er jo vikinger. Amazonerne var kvindelige krigere, når deres døtre blev omkring 14 år, så skar de brystet af dem, så de blev bedre bueskytter, bedre til at forsvare sig, og vi er amazonens børn, som også bliver store krigere engang. Det er derfor vi er med på billedet, med på jagt.<br />
            Amazonen er helt vildt stærk
            <br /><br /><br />
        </p>
      ) : (
        <p id='uk'>My mother is a visual artist, she a photograph.<br /><br />
        By son Harald 2007<br /><br />        
        Amazon<br />
        The grain was high in the field, in the gap at the end of the garden, the sun was shining, and the weather was nice, so me, little brother and mother went out into the field, in the tall straw, and mother said: It must be now before they harvest the grain, then we put ourselves out there. Mom had the camera on a tripod, and then we took pictures with the self-timer, and the straws tickled, and Esajas jumped around. Mom always says hold it, hold it, when she feels the picture is there, but it's probably only something she can feel, because we can't see in the camera when we take the picture.<br /><br />
         Just as we finished taking the picture, there was a roar and then the biggest harvester I've ever seen just came rolling away, we were pretty lucky we finished the picture then, because otherwise that picture would never have been. For me, the amazon picture is a big memory of that summer 2007.<br /><br />
          When mom said we should make an amazon picture, I thought she was going to go in and get a knife and cut off her breasts, so we can make the picture right. Sometimes it's hard work when we do the big pictures, I have to stand in the same position for a long time, but that's fine, because it pays off in the end to do it well. It's a piece of deeply serious work that I take seriously, otherwise it won't be right, and then probably no one will ever look at it.<br /><br />
          We are Amazons in our family. Mother is naked in almost all the pictures, so we are Vikings after all. The amazons were female warriors, when their daughters turned about 14, they cut off their breasts so they became better archers, better at defending themselves, and we are the children of the amazons, we will also be great warriors one day, that's why we are in the pictures, along for the hunt.<br />
          The Amazon is strong.
          <br /><br /><br />        
        </p>
      );
       break;
    }
    case 2: {
      txt = e === 'dk' ? (
        <p id='dk'>Morgenbordet<br /><br />
           Vi sidder og spiser morgenmad, en typisk forårs/sommermorgen, påske er det. Påskeliljerne er lige sprunget ud, der ligger et par gode Anders And blade, vores yndlingslysestager, og æblejuicen er på sin plads, sammen med kamilleteen og kogt vand. Mor er amazonen igen, hun sidder i midten, og skuer ned på byttet, mens Esajas og jeg hendes to kriger sønner indtager føden.<br />
           Vi er i havestuen. Det er en helt almindelig hverdagsmorgen, så siger mor: Det her skal vi da have et billede af, så henter hun kameraet/selvudløseren, og så har vi 10 sekunder til at sætte os, så tager hun billeder, og derfor sidder vi jo og spiser imens, for vi er jo midt i vores morgenmad, et af dem er så en perle.
            <br /><br /><br />
        </p>
      ) : (
        <p id='uk'>The morning table.<br /><br />
        We sit and eat breakfast, a typical spring/summer morning, it's Easter, the daffodils have just popped out, there are a few good Donald Duck magazines, our favorite candlesticks, and the apple juice is in its place together with the chamomile tea, boiled water. Mother is the Amazon again, she sits in the middle and looks down on the prey, but Esajas and I, her two warrior sons, eat the food.<br />
        We are in the garden room. It's an ordinary weekday morning, then mother says: We need a picture of it, then she gets the camera/self-timer, and then we have 10 seconds to sit down, then she takes pictures, and therefore we sit and eat meanwhile, because we are in the middle of our breakfast, one of them is such a gem.        
          <br /><br /><br />        
        </p>
      );
      break;
   }
   case 3: {
    txt = e === 'dk' ? (
      <p id='dk'>Faithfulluntodeath<br /><br />
      I sommerhusets have i græsset under den store gran. Det er efterår og bladene er ved at falde fra træerne. Jeg skulle lægge mig i græsset med min bue, og det var koldt, men det tog ikke så lang tid, og jeg tror min lillebror syntes det var lige så koldt. Han lå i græsset med sin elskede dukke.<br />
      Min onkel døde det år, vi lavede de billeder, så meget af det mor lavede det år symboliserede døden. Jeg, synes selv jeg ligner, en engel, med bue og vinger, der er på vej op i himmelen.
      <br /><br /><br />
      </p>
    ) : (
      <p id='uk'>Faithfulluntodeath<br /><br />
      In the cottage garden in the grass under the big fir tree, it is autumn, and the leaves are falling from the trees, I had to lie down in the grass with my bow and it was cold, but it didn't take that long, and I think my little brother thought it was just as cold, he was lying in the grass with his beloved doll.<br />  
      My uncle died the year we made those pictures, so much of what mom made that year symbolized death, i think myself i look like an angel with a bow and wings going up to heaven.     
        <br /><br /><br />        
      </p>
    );
    break;
  }
  case 4: {
    txt = e === 'dk' ? (
      <p id='dk'>Kunst<br /><br />
      Kunst er mange ting, men billedkunst med fotografi er nok det jeg kender bedst. Kunst er når man laver noget man er god til. Vi er kunstnere i vores familie, der er mange slags kunst, der er tryllekunst, musikkunst. Kunst er, når man gør det, man er aller bedst til i hele verden.<br /><br />
      Vi er meget vigtige i mors kunst, uden os var der ingen billeder. Mange af mors billeder er vi med på, det er de bedste af dem, det er vores familie, det er ærlige portrætter. Når man er kriger, skal man bære en masse ting, det kan være ret hårdt, så det kan også være hårdt at være en amazoneunge. En amazonemor er hård, sej og hun laver kunst.
      <br /><br />
      </p>
    ) : (
      <p id='uk'>Art<br /> <br /> 
      Art is many things, but visual art with photography is probably what I know best, art is when you do something, you are good at. We are artists in our family, there are many kinds of art, there is magic art, musical art, art is when you do what you do best in the whole wide world.<br /> <br /> 
      We are very important in mother's art, without us there would be no pictures, we are in many of mother's pictures, they are the best of them, they are our family, they are honest portraits. When you are a warrior, you must carry a lot of things, it can be quite hard, so being an amazon cub can be hard too. An Amazon mother is tough, and she makes art.       
      <br /><br />   
      </p>
    );
    break;
  }
  case 5: {
    txt = e === 'dk' ? (
        <p style={{textAlign: 'center'}}><i>Kvinden er mit instrument</i><br />
        <br />Liv Carlé Mortensen<br />
        <br />8 marts 2024<br /><br />
        <img style={{width: '20px'}} src="http://livcarlemortensen.com/content/kvindetegn.png" alt="Fine Art Photography" /><br /></p>) : (
        <p style={{textAlign: 'center'}}><i>The woman is my instrument</i><br />
        <br />Liv Carlé Mortensen<br />
        <br />8. march 2024<br /><br />
        <img style={{width: '20px'}} src="http://livcarlemortensen.com/content/kvindetegn.png" alt="Fine Art Photography" /></p>
      );
      break;
    }
  default: {
    break;
  }
};
    
  return txt;
}