import React from "react";
import { useLocation } from 'react-router-dom';
import {updateMenu} from './jsfunctions';

  function BugerIcon({ open, ...props }) {
    const location = useLocation();
    React.useEffect(() => {
      updateMenu();
    }, [location]);

    return (
      <div className={open ? "burger-menu open" : 'burger-menu'} {...props}>
        <div className="bar1" key="b1" />
        <div className="bar2" key="b2" />
        <div className="bar3" key="b3" />
      </div>
    );
  }
  export default BugerIcon;
