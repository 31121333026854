export const iOS = () => {
return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform)
// iPad on iOS 13 detection
|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
export const updateMenu = () => {
    window.location.href.includes('#') || window.location.href.includes('about') || window.location.href.includes('/work/') ? document.getElementById('bugerMenu').classList.remove("hidden") :
    document.getElementById('bugerMenu').classList.add("hidden");
}