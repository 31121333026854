import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {erik} from './aboutPages/erik';
import {harald} from './aboutPages/harald';
import './about.css';

function About() {
  const [alignment, setAlignment] = React.useState('dk');

  const handleChange = (event, newAlignment) => {
    if(newAlignment === null) newAlignment = 'dk';
    setAlignment(newAlignment);
  };

  return (
    <div className='about'>
      <ToggleButtonGroup
        color="error"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        size="small"
        style={{ float: 'right', marginTop: '40px', marginRight: '8px'}}
      >
        <ToggleButton value="dk">DK</ToggleButton>
        <ToggleButton value="uk">UK</ToggleButton>
      </ToggleButtonGroup>

      <div className='erikBrok'>
        <div>
          <img className='about_image_01' src="http://livcarlemortensen.com/wp/wp-content/uploads/worrior01.jpg" alt="Fine Art Photography" />
          {erik(alignment)}
          <img className='about_image_02' src="http://livcarlemortensen.com/wp/wp-content/uploads/amazon01.jpg" alt="Fine Art Photography" />
          {harald(alignment, 1)}
          <img className='about_image_03' src="http://livcarlemortensen.com/wp/wp-content/uploads/amazon64.jpg" alt="Fine Art Photography" />
          {harald(alignment, 2)}
          <img className='about_image_04' src="http://livcarlemortensen.com/wp/wp-content/uploads/amazon87.jpg" alt="Fine Art Photography" />
          {harald(alignment, 3)}
          <img className='about_image_05' src="http://livcarlemortensen.com/wp/wp-content/uploads/amazon17.jpg" alt="Fine Art Photography" />
          {harald(alignment, 4)}
          <br />
        </div>
        <div>{harald(alignment, 5)}</div>
      </div>
    </div>
  );
}

export default About;