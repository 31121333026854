import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, FreeMode, Thumbs, Navigation, Mousewheel } from 'swiper/modules';
import {isMobile, useMobileOrientation} from 'react-device-detect';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import 'swiper/css/effect-creative';
import {wpImages} from './sliderInfo/wpImages';

function SlideShow({imgFolder}) {
  const imgInfo = wpImages(imgFolder);
  const imgs = [];
  let a = 0;
  imgInfo.map(info => {
    let img = {};
    a++;
    img.path = info.path;
    img.title = info.title;
    img.count = a;
    imgs.push(img);
    return img;
  });

  const setLoop = imgs.length > 5;
  let ls = useMobileOrientation();
  let thumpCount =  ls.isLandscape ? 11 : 4

  let slideStyle = ls.isLandscape && isMobile ? {display: 'block'} : isMobile ? {display: 'block'} : {height: '88vh', display: 'block'};
  
  let imgStyle = ls.isLandscape && isMobile ? {maxHeight: '80vh', display: 'block'} : isMobile && imgFolder === 'spiral' ? {maxHeight: '70vh', borderTop: '25vh solid #fff', maxWidth: '90vw'} : isMobile ? {maxHeight: '70vh', borderTop: '5vh solid #fff', maxWidth: '90vw'} : {maxHeight: '75vh', display: 'block', borderTop: '5vh solid #fff'} ;
  
  let titleStyle = ls.isLandscape && isMobile ? {margin: '1vh auto', position: 'absolute', right: '2vw', bottom: '2vh'} : isMobile && imgFolder === 'spiral' ? {margin: '1vh auto 15vh 0'} : {margin: '1vh auto'};

  // const countStyle = {fontSize: '11px', margin: '5px auto'};
  let thumbStyle = ls.isLandscape && isMobile ? { display: 'none'} : { height: "8vh", objectFit: 'contain', marginTop: "0"};

  React.useEffect(() => {}, [ls]);


  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="slide-container" id='ib' key={{thumpCount}}>
    <Swiper
      style={{
        '--swiper-navigation-color': '#c60000',
        '--swiper-pagination-color': '#c60000',
        '--swiper-theme-color': '#c60000',
        '--swiper-preloader-color' : '#c60000',
      }}
      mousewheel={true}
      loop={setLoop}
      spaceBetween={300}
      speed={isMobile ? '100' : '400'}
      zoom={true}
      navigation={!isMobile}
      thumbs={{ swiper: thumbsSwiper }}
      modules={[Zoom, Thumbs, Navigation, Mousewheel]}
      className="FineArtPhotography"
      grabCursor={true}
    >
      {imgs.map((img, index) => (
        <SwiperSlide key={index+500}>
        <div className="swiper-zoom-container" style={slideStyle} key={index+5000}>
          <img src={img.path} alt={img.title} style={imgStyle} loading="lazy" />
          <div className="swiper-lazy-preloader" />
          <p style={titleStyle}>{img.title}</p>
        </div>
      </SwiperSlide>
      ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={setLoop}
        spaceBetween={5}
        slidesPerView={thumpCount}
        freeMode={true}
        mousewheel={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs, Mousewheel]}
        className="FineArtPhotographyThumb"
        style={{
          '--swiper-navigation-color': '#c60000',
          '--swiper-pagination-color': '#c60000',
          '--swiper-theme-color': '#c60000',
          '--swiper-preloader-color' : '#c60000',
        }}
      >
        {imgs.map((img, index) => (
          <SwiperSlide key={index}>
            <img style={thumbStyle} src={img.path} alt={img.title} loading="lazy" />
            <div className="swiper-lazy-preloader" />
          </SwiperSlide>
        ))}
        </Swiper>
    </div>
  );
}

export default SlideShow;